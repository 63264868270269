@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;700&display=swap);
body {
  margin: 0;
}

/* =============================================== Helper classes */

.flex-fill { flex: 1 1 auto!important; }
.d-md-flex { display:block; }
@media (min-width: 768px) { 
  .d-md-flex { display:flex; }
  .pe-md-4 { padding-right: 40px; }
}
.d-sm-flex { display:block; }
@media (min-width: 576px) { 
  .d-sm-flex { display:flex; }
  .pe-sm-4 { padding-right: 40px; }
}

/* =============================================== Typography */

body {
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* =============================================== UI in Login */

.labeledField .MuiInputBase-root {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.labeledField .MuiOutlinedInput-notchedOutline {
  border-color: rgba(51, 122, 183, 0.5);
  /*border-left-color: rgb(225, 241, 255)!important;*/
}
.fieldLabel {
  border-color: rgba(51, 122, 183, 0.5)!important;
  border-right-color: rgb(225, 241, 255)!important;
  overflow:hidden;
  white-space: nowrap;
}


.gridContainer {
  max-height: 96vh;
}

.container_orders-list {
  height: 100vh;
  /* height: calc(100vh - 20px); */
  overflow-x:hidden;
  overflow-y:hidden;
  display:flex;
  flex-direction: column;
  min-width:100%;
}

.container_history-list {
  height: 90vh;
  /* height: calc(100vh - 40px);  */
  overflow-x:hidden;
  overflow-y:hidden;
  display:flex;
  flex-direction: column;
  min-width:100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: #6c757d;
  padding-right: 1rem;
}


#tidio-chat iframe { bottom: 3em !important; }
@media only screen and (max-width: 980px) {
    #tidio-chat iframe { bottom: 15px !important; }
}

/* PANEL 1 / ADDRESS BOOK ===================================== */

.panel-item-box {
    padding: 3%;
    margin: 0;   
}

.panel-item-box-unselected {
    padding: 1% 2%;
    margin: 0;
}

.panel-item-title {
    margin: 0;
    padding: 2% 0;
}

.panel-item-info {
    margin: 0;
    padding: 3% 0;
}

h3 {
    font-size: 16px;
    font-weight: regular;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

p {
    font-size: 14px;
    line-height: 1.5;
    margin: 0;
    padding: 0;
}

.panel-address-form {
    transform:translateY(-50%);
}

.fixedElement {  
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
}
.addNewAddressForm {
    padding: 2%;  
    border-width: 1px;
    border-style: solid;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    position: absolute;
    z-index: 1;
    /* max-width: 32%; */
}

.editAddressForm {
    padding: 4%;  
    border-width: 1px;
    border-style: solid;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    position: absolute;
    z-index: 1;
    inset: 55px auto auto 0px !important;
    /* max-width: 32%; */
}
.panel-list {
    max-height: 650px;
    min-height: 650px;
    overflow-y: scroll;
    margin: 2% 0;
}

.panel-list .panel-list-container {
    padding: 0px;
    margin: 0px 4px 0px 0px;
    border-radius: 0.25rem;
    border: 1px solid rgba(0,0,0,.125);
}

.panel-list .panel-list-container .panel-list-item {
    margin: 0px;
    padding: 2px;
}

/* scrollbar */

.panel-list::-webkit-scrollbar-track
{
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color:#E1F1FF;
}
.panel-list::-webkit-scrollbar
{
    width: 10px;
    background-color:#E1F1FF;
}
.panel-list::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: #337AB7;
}

.tooltip-inner {
    text-align:left!important;
    background-color:#337AB7!important;
}
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-top .tooltip-arrow::before {
    border-top-color:#337AB7!important;
}
html {
    width: '100%';
}

#account-picker-wrapper {
    position:relative;
}

#account-picker {
    position:absolute;
    top:10px;
    right:80px;
    z-index:10;
    width:350px;
}

#indicator_duration {
    position:absolute;
    top:5px;
    right:1160px;
    z-index:10;
    width:50px;
}

@media (max-width: 1920px) {
    #indicator_duration {
        position:absolute;
        top:5px;
        right:900px;
        z-index:10;
        width:50px;
    } 
}

@media (max-width: 1360px) {
    #indicator_duration {
        position:absolute;
        top:5px;
        right:620px;
        z-index:10;
        width:50px;
    } 
}

@media (max-width: 767.98px) {
    #account-picker {
        position:relative;
        width:100%;
        max-width:100%;
        height: -webkit-max-content;
        height: max-content;
        top: unset;
        right: unset;
    }
    #account-picker-wrapper {
        padding:5px 5px 5px 5px;
    }  
    #indicator_duration {
        position:absolute;
        top:5px;
        right:200px;
        z-index:10;
        width:50px;
    }  
}

.di_badge {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #337AB7;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
    transition: all 0.5s;
}

.di_badge_check {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #65CCB4;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
}

.a_button {
    display: block;
    width: 115px;
    height: 25px;
    background: #337AB7;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
}


.fixedElement {  
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
}

.gallery {
    z-index: 10;
    position: absolute;
    background-color: rgba(255,255,255,0.5);
    margin-top:0;
    height:100%;
    width:30%;
}


.img-wrapper {
    overflow: hidden;
  }
  
  img.hover-zoom {
    transition: all 0.3s ease 0s;
    width: 100%;
  }
  img.hover-zoom:hover {
    transform: scale(1.8);
    position: relative;
    z-index: 999;
  }


  .NewOrder .MuiAccordionSummary-content { align-items: center; }
tr {
    border: 1px solid gray;
}

.printfont{
    font-size:18px;
 }
 
 .titlefont{
    font-size:26px;
 }
 
 .td_head_title{
    width:40%;
    text-align:left;
    vertical-align: text-top;
    height: 50px;    
}

 .td_title{
     width:30%;
     text-align: left;
     height: 50px; 
     padding-left: 25px;   
 }
 
 .td_content{
     width:70%;
 }

 .black {
    color:#000;
}

.printTable{
    width: 100%;
    border-collapse: collapse;
}


.td_title{
    text-align: left; 
}
.page-banner {
    width:100%;
    padding:60px 0;
    margin: 0px;
    text-align: center;
}

.page-banner .title {
    color: white;
    font-size: 55px;
    margin:0 0 15px 0;
}

@media screen and (max-width: 576px) {
    .page-banner .title {
        font-size: 48px;
    }
}

.page-banner .subheading {
    color: white;
    margin: 0px;
}
.panel {
    box-shadow: 0px 0px 20px 8px rgba(0,0,0,0.12);
    background-color: white;
    padding: 20px;
    margin: 5px;
    border-radius: 4px;
}
#pageContent {
    padding: 0 3px;
}
@media (min-width: 992px) {
    .panel {
        margin:10px;
    }
    #pageContent {
        padding: 0 5px;
    }
}
@media (min-width: 1400px) {
    .panel {
        margin:15px;
    }
    #pageContent {
        padding: 0 8px;
    }
}

/* Typography in panels ========================== */

.panel h6 {
    text-transform: uppercase;
    margin: 0 0 0.5rem;
    color: rgb(64, 64, 68);
    font-size: 0.8rem;
    font-weight: 600;
  }
.panel h3 {
    margin: 0 0 0.5rem;
    color: black;
    font-size: 1rem;
    font-weight: 700;
}

/* copy button */
.panel h3 .MuiIconButton-sizeSmall {
    padding:0;
    /* margin-left: 0.5rem; */
}
.panel h3 .MuiIconButton-sizeSmall:hover {
    background-color: transparent;
}
.panel h3 .MuiIconButton-sizeSmall .MuiSvgIcon-root {
    width: 0.6em;
    height: 0.6em;
    font-size: 1.5rem;
}

@media only screen and (max-width: 1279.89px) {
    .panel .text-mobile-end {
        text-align: end;
    }
}

/* Stepper ========================== */
.panel .border-mobile-bottom {
    padding-left:2%;
}
@media only screen and (max-width: 1279.89px) {
    .panel .MuiStepper-root {
        padding: 35px 0 0;
    }
    .panel .border-mobile-bottom {
        border-bottom: 2px solid rgb(207, 214, 224);
        padding-left:0;
    }
}

/* Expandable list ===================== */

.panel .MuiList-root {
    border:1px solid #337AB7;
    border-radius: 8px;
}
.panel .MuiList-padding {
    padding: 8px 12px;
}
.panel .MuiList-root .MuiListItem-root {
    cursor: pointer;
}
.panel .MuiList-root .MuiCollapse-root {
    border-top:1px solid #337AB7;
    margin-top:15px;
    padding-top:15px;
}
.panel .MuiList-root .MuiCollapse-root h6 {
    margin-bottom:5px;
}
.panel .MuiList-root .MuiCollapse-root p {
    margin-bottom:20px;
}
.a_button {
    display: block;
    width: 115px;
    height: 25px;
    background: #337AB7;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
}

.side_button{
    float:center;
    margin-top:5px;
    width: 180px
}

.side_icon{
    vertical-align: middle;
    transform: scale(1)
}

/*---------------------------------------------*/

.NewOrder {
    position:relative;
    width:100%;
    overflow: hidden;
}
.NewOrder #bannerBackground {
    position:absolute;
    height:320px;
    top:0;
    left:0;
    right:0;
    z-index:-1;
    background: 
        linear-gradient(rgba(0,0,0, 0.4), rgba(0,0,0, 0.4)), 
        url(/static/media/orderDetail_backgroundImg.5481f55d.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
#pageContent {
    max-width:1800px;
    margin:0 auto;
}
/* .NewOrder .fileDropContainer {
    position:fixed;
    bottom:0;
    left:0;
    right:0;
} */

/* Add Files Dialog ============================================= */

/* .addFilesDialog {} */

/* Buttons ======================= */
.buttonsGrid .MuiButton-root {
    display:block;
}
.buttonsGrid .MuiButton-root .MuiButton-label {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
}
/*#mainHeader { display:none; }*/
/*body { padding-top:0; }*/
.Login {
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-top:-64px;
}
.login-wrapper {
    max-width:350px;
    margin:0 auto;
}
.login-wrapper-inner {
    margin:0 10px;
    border:1px solid rgba(51, 122, 183, 0.2);
    border-radius:3px;
    padding:20px;
}

.MuiTypography-subtitle1 {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 14px!important;
    font-weight:600!important;
    letter-spacing: 0!important;
}

.di_badge {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #337AB7;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
    transition: all 0.5s;
}

.di_badge_check {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #65CCB4;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
}


/*#mainHeader { display:none; }*/
/*body { padding-top:0; }*/
.tracking {
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-top:-80px;
}
.tracking-wrapper {
    max-width:350px;
    margin:0 auto;
}
.tracking-wrapper-inner {
    margin:0 10px;
    border:1px solid rgba(51, 122, 183, 0.2);
    border-radius:3px;
    padding:20px;
}

.MuiTypography-subtitle1 {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 14px!important;
    font-weight:600!important;
    letter-spacing: 0!important;
}



