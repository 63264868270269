@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600;700&display=swap');
body {
  margin: 0;
}

/* =============================================== Helper classes */

.flex-fill { flex: 1 1 auto!important; }
.d-md-flex { display:block; }
@media (min-width: 768px) { 
  .d-md-flex { display:flex; }
  .pe-md-4 { padding-right: 40px; }
}
.d-sm-flex { display:block; }
@media (min-width: 576px) { 
  .d-sm-flex { display:flex; }
  .pe-sm-4 { padding-right: 40px; }
}

/* =============================================== Typography */

body {
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* =============================================== UI in Login */

.labeledField .MuiInputBase-root {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.labeledField .MuiOutlinedInput-notchedOutline {
  border-color: rgba(51, 122, 183, 0.5);
  /*border-left-color: rgb(225, 241, 255)!important;*/
}
.fieldLabel {
  border-color: rgba(51, 122, 183, 0.5)!important;
  border-right-color: rgb(225, 241, 255)!important;
  overflow:hidden;
  white-space: nowrap;
}


.gridContainer {
  max-height: 96vh;
}

.container_orders-list {
  height: 100vh;
  /* height: calc(100vh - 20px); */
  overflow-x:hidden;
  overflow-y:hidden;
  display:flex;
  flex-direction: column;
  min-width:100%;
}

.container_history-list {
  height: 90vh;
  /* height: calc(100vh - 40px);  */
  overflow-x:hidden;
  overflow-y:hidden;
  display:flex;
  flex-direction: column;
  min-width:100%;
}