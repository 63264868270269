.page-banner {
    width:100%;
    padding:60px 0;
    margin: 0px;
    text-align: center;
}

.page-banner .title {
    color: white;
    font-size: 55px;
    margin:0 0 15px 0;
}

@media screen and (max-width: 576px) {
    .page-banner .title {
        font-size: 48px;
    }
}

.page-banner .subheading {
    color: white;
    margin: 0px;
}