.addNewAddressForm {
    padding: 2%;  
    border-width: 1px;
    border-style: solid;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    position: absolute;
    z-index: 1;
    /* max-width: 32%; */
}

.editAddressForm {
    padding: 4%;  
    border-width: 1px;
    border-style: solid;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;
    position: absolute;
    z-index: 1;
    inset: 55px auto auto 0px !important;
    /* max-width: 32%; */
}