/*#mainHeader { display:none; }*/
/*body { padding-top:0; }*/
.Login {
    min-height:100vh;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    margin-top:-64px;
}
.login-wrapper {
    max-width:350px;
    margin:0 auto;
}
.login-wrapper-inner {
    margin:0 10px;
    border:1px solid rgba(51, 122, 183, 0.2);
    border-radius:3px;
    padding:20px;
}

.MuiTypography-subtitle1 {
    font-family: 'Open Sans', sans-serif!important;
    font-size: 14px!important;
    font-weight:600!important;
    letter-spacing: 0!important;
}

.di_badge {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #337AB7;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
    transition: all 0.5s;
}

.di_badge_check {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    background-color: #65CCB4;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-right: 15px;
}

