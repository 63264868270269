.a_button {
    display: block;
    width: 115px;
    height: 25px;
    background: #337AB7;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    line-height: 25px;
}

.side_button{
    float:center;
    margin-top:5px;
    width: 180px
}

.side_icon{
    vertical-align: middle;
    transform: scale(1)
}

/*---------------------------------------------*/

.NewOrder {
    position:relative;
    width:100%;
    overflow: hidden;
}
.NewOrder #bannerBackground {
    position:absolute;
    height:320px;
    top:0;
    left:0;
    right:0;
    z-index:-1;
    background: 
        linear-gradient(rgba(0,0,0, 0.4), rgba(0,0,0, 0.4)), 
        url(./components/pageBanner/orderDetail_backgroundImg.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}
#pageContent {
    max-width:1800px;
    margin:0 auto;
}
/* .NewOrder .fileDropContainer {
    position:fixed;
    bottom:0;
    left:0;
    right:0;
} */

/* Add Files Dialog ============================================= */

/* .addFilesDialog {} */

/* Buttons ======================= */
.buttonsGrid .MuiButton-root {
    display:block;
}
.buttonsGrid .MuiButton-root .MuiButton-label {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: start;
}