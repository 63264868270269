tr {
    border: 1px solid gray;
}

.printfont{
    font-size:18px;
 }
 
 .titlefont{
    font-size:26px;
 }
 
 .td_head_title{
    width:40%;
    text-align:left;
    vertical-align: text-top;
    height: 50px;    
}

 .td_title{
     width:30%;
     text-align: left;
     height: 50px; 
     padding-left: 25px;   
 }
 
 .td_content{
     width:70%;
 }

 .black {
    color:#000;
}

.printTable{
    width: 100%;
    border-collapse: collapse;
}


.td_title{
    text-align: left; 
}